import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Input from 'components/Input'
import Button from 'components/Button'
import { InputAdornment, IconButton } from '@material-ui/core'
import { palette } from 'styles/colors'
import { Close } from '@material-ui/icons'
import Select from 'components/Select'
import Checkbox from 'components/Checkbox'

const standardSelect = [
  {
    id: 'yes',
    fullName: 'Да'
  },
  {
    id: 'no',
    fullName: 'Нет'
  }
]

const recetionTypeSelect = [
  {
    id: 'duty',
    fullName: 'Дежурные'
  },
  {
    id: 'planned',
    fullName: 'Плановые'
  }
]

const ControlBlockExport = ({
  classes,
  formik,
  searchText,
  exportText,
  fieldName,
  onClickExport,
  handleChange,
  fileInputRef,
  isLoading,
  specialists,
  withActions,
  patients
}) => {
  return (
    <div className={classes.wrapper}>
      <div className={`${classes.block} ${classes.content}`}>
        <div className={classes.block}>
          <Button
            text={exportText}
            otherStyles={{
              width: '270px',
              height: '45px',
              backgroundColor: palette.blueButton,
              color: palette.white,
              marginRight: '2em',
              marginLeft: '2em',
              marginBottom: '1em'
            }}
            onClick={onClickExport}
            loader={isLoading}
          />
        </div>

        <input
          onChange={handleChange}
          multiple={false}
          ref={fileInputRef}
          type="file"
          hidden
        />
        <div className={classes.search}>
          <Input
            formik={formik}
            fieldName={fieldName}
            label={searchText}
            endAdornment={
              formik.values.search.length > 0 ? (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle"
                    style={{
                      color: palette.blue,
                      width: '40px',
                      height: '40px'
                    }}
                    onClick={() => {
                      formik.setFieldValue('search', '')
                      formik.handleSubmit()
                    }}
                  >
                    <Close />
                  </IconButton>
                </InputAdornment>
              ) : (
                <></>
              )
            }
          />
          <Button
            text={'Найти'}
            otherStyles={{
              width: '200px',
              height: '45px',
              backgroundColor: palette.blueButton,
              color: palette.white,
              marginRight: '2em',
              marginLeft: '2em'
            }}
            type="submit"
          />
        </div>
      </div>
      {withActions && (
        <div className={classes.block}>
          {specialists && specialists.length > 0 && (
            <div className={classes.block}>
              <Select
                formik={formik}
                selectValues={specialists}
                fieldName={'specialistId'}
                otherStyles={{ marginLeft: '2em', marginBottom: '-10px' }}
                selectorType={'specialistReception'}
                title={'Специалист'}
                smallWidth
              />
              <IconButton
                aria-label="toggle"
                style={{
                  color: palette.blue,
                  width: '40px',
                  height: '40px',
                  marginTop: '20px',
                  marginLeft: '-20px'
                }}
                onClick={() => {
                  formik.setFieldValue('specialistId', '')
                }}
              >
                <Close />
              </IconButton>
            </div>
          )}
          {patients && patients.length > 0 && (
            <div className={classes.block}>
              <Select
                formik={formik}
                selectValues={patients}
                fieldName={'userId'}
                otherStyles={{ marginLeft: '2em', marginBottom: '-10px' }}
                selectorType={'specialistReception'}
                title={'Пациент'}
                smallWidth
              />
              <IconButton
                aria-label="toggle"
                style={{
                  color: palette.blue,
                  width: '40px',
                  height: '40px',
                  marginTop: '20px',
                  marginLeft: '-20px'
                }}
                onClick={() => {
                  formik.setFieldValue('userId', '')
                }}
              >
                <Close />
              </IconButton>
            </div>
          )}
          <div className={classes.block}>
            <Select
              formik={formik}
              selectValues={standardSelect}
              fieldName={'isReferraled'}
              otherStyles={{ marginLeft: '2em', marginBottom: '-10px' }}
              selectorType={'specialistReception'}
              title={'Направлен'}
              smallWidth
            />
            <IconButton
              aria-label="toggle"
              style={{
                color: palette.blue,
                width: '40px',
                height: '40px',
                marginTop: '20px',
                marginLeft: '-20px'
              }}
              onClick={() => {
                formik.setFieldValue('isReferraled', '')
              }}
            >
              <Close />
            </IconButton>
          </div>
          <div className={classes.block}>
            <Select
              formik={formik}
              selectValues={standardSelect}
              fieldName={'success'}
              otherStyles={{ marginLeft: '2em', marginBottom: '-10px' }}
              selectorType={'specialistReception'}
              title={'Успешный прием'}
              smallWidth
            />
            <IconButton
              aria-label="toggle"
              style={{
                color: palette.blue,
                width: '40px',
                height: '40px',
                marginTop: '20px',
                marginLeft: '-20px'
              }}
              onClick={() => {
                formik.setFieldValue('success', '')
              }}
            >
              <Close />
            </IconButton>
          </div>
          <div className={classes.block}>
            <Select
              formik={formik}
              selectValues={recetionTypeSelect}
              fieldName={'receptionType'}
              otherStyles={{ marginLeft: '2em', marginBottom: '-10px' }}
              selectorType={'specialistReception'}
              title={'Тип приема'}
              smallWidth
            />
            <IconButton
              aria-label="toggle"
              style={{
                color: palette.blue,
                width: '40px',
                height: '40px',
                marginTop: '20px',
                marginLeft: '-20px'
              }}
              onClick={() => {
                formik.setFieldValue('receptionType', '')
              }}
            >
              <Close />
            </IconButton>
          </div>
          <div className={classes.checkbox}>
            <Checkbox label="Сегодня" formik={formik} fieldName={'today'} />
          </div>
        </div>
      )}
    </div>
  )
}

const styles = () => ({
  search: {
    display: 'flex',
    alignItems: 'baseline'
  },
  block: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  wrapper: {
    flexDirection: 'column',
    width: '100%'
  },
  content: {
    justifyContent: 'space-between'
  },
  checkbox: {
    marginTop: '1.2em',
    marginLeft: '2em'
  }
})

export default withStyles(styles)(ControlBlockExport)
