export const columnsNotifications = [
  {
    id: 'status',
    label: 'Статус',
    align: 'center'
  },
  {
    id: 'date',
    label: 'Дата',
    align: 'center'
  },
  {
    id: 'title',
    label: 'Заголовок',
    align: 'center'
  },
  {
    id: 'message',
    label: 'Сообщение',
    align: 'center'
  }
]

export const columnsSpecialists = [
  {
    id: 'name',
    label: 'ФИО',
    align: 'center'
  },
  {
    id: 'speciality',
    label: 'Специальность',
    align: 'center'
  },
  {
    id: 'receptions',
    label: 'Приемы',
    align: 'center'
  },
  {
    id: 'position',
    label: 'Позиция в очереди',
    align: 'center'
  },
  {
    id: 'sign',
    label: 'Подпись',
    align: 'center'
  },
  {
    id: 'edit',
    label: 'Редактировать',
    align: 'center'
  },
  {
    id: 'delete',
    label: 'Удалить',
    align: 'center'
  }
]

export const columnsEducations = [
  {
    id: 'type',
    label: 'Тип образования',
    align: 'center'
  },
  {
    id: 'university',
    label: 'Вуз/Клиника',
    align: 'center'
  },
  {
    id: 'speciality',
    label: 'Специальность',
    align: 'center'
  },
  {
    id: 'year',
    label: 'Год окончания',
    align: 'center'
  },
  {
    id: 'edit',
    label: '',
    align: 'center'
  },
  {
    id: 'delete',
    label: '',
    align: 'center'
  }
]

export const columnsWorks = [
  {
    id: 'place',
    label: 'Место работы',
    align: 'center'
  },
  {
    id: 'position',
    label: 'Должность',
    align: 'center'
  },
  {
    id: 'start',
    label: 'Дата начала',
    align: 'center'
  },
  {
    id: 'end',
    label: 'Дата окончания',
    align: 'center'
  },
  {
    id: 'edit',
    label: '',
    align: 'center'
  },
  {
    id: 'delete',
    label: '',
    align: 'center'
  }
]

export const columnsCourses = [
  {
    id: 'name',
    label: 'Название курсов',
    align: 'center'
  },
  {
    id: 'year',
    label: 'Год',
    align: 'center'
  },
  {
    id: 'edit',
    label: '',
    align: 'center'
  },
  {
    id: 'delete',
    label: '',
    align: 'center'
  }
]

export const columnsAppointments = [
  {
    id: 'time',
    label: 'Время',
    align: 'center'
  },
  {
    id: 'name',
    label: 'Фамилия Имя',
    align: 'center'
  },
  {
    id: 'phone',
    label: 'Номер телефона',
    align: 'center'
  },
  {
    id: 'edit',
    label: '',
    align: 'center'
  }
]

export const columnsPatients = [
  {
    id: 'name',
    label: 'ФИО',
    align: 'center'
  },
  {
    id: 'phone',
    label: 'Номер телефона',
    align: 'center'
  },
  {
    id: 'status',
    label: 'Статус оплаты',
    align: 'center'
  },
  {
    id: 'abonement_expired',
    label: 'Дата начала абонемента',
    align: 'center'
  },
  {
    id: 'limit_amout',
    label: 'Осталось приемов',
    align: 'center'
  },
  {
    id: 'patient_status',
    label: 'Статус пациента',
    align: 'center'
  },
  {
    id: 'receptions',
    label: 'Приемы',
    align: 'center'
  },
  {
    id: 'profile',
    label: 'Профиль',
    align: 'center'
  },
  {
    id: 'delete',
    label: 'Удалить',
    align: 'center'
  }
]

export const columnsHistory = [
  {
    id: 'date',
    label: 'Дата',
    align: 'center'
  },
  {
    id: 'details',
    label: 'Подробнее',
    align: 'center'
  },
  {
    id: 'specialist',
    label: 'Специалист',
    align: 'center'
  }
]

export const columnsClinics = [
  {
    id: 'name',
    label: 'Название',
    align: 'center'
  },
  {
    id: 'type',
    label: 'Тип',
    align: 'center'
  },
  {
    id: 'profile',
    label: 'Профиль',
    align: 'center'
  },
  {
    id: 'delete',
    label: 'Удалить',
    align: 'center'
  }
]

export const columnsPharmacies = [
  {
    id: 'name',
    label: 'Название',
    align: 'center'
  },
  {
    id: 'profile',
    label: 'Профиль',
    align: 'center'
  },
  {
    id: 'delete',
    label: 'Удалить',
    align: 'center'
  }
]

export const columnsModerator = [
  {
    id: 'login',
    label: 'Логин',
    align: 'center'
  },
  {
    id: 'password',
    label: 'Пароль',
    align: 'center'
  },
  {
    id: 'activate',
    label: 'Активировать',
    align: 'center'
  },
  {
    id: 'moderator',
    label: 'Режим модератора',
    align: 'center'
  },
  {
    id: 'edit',
    label: 'Редактировать',
    align: 'center'
  },
  {
    id: 'delete',
    label: 'Удалить',
    align: 'center'
  }
]

export const columnsPromocodes = [
  {
    id: 'code',
    label: 'Промокод',
    align: 'center'
  },
  {
    id: 'title',
    label: 'Название',
    align: 'center'
  },
  {
    id: 'isActive',
    label: 'Статус',
    align: 'center'
  },
  {
    id: 'edit',
    label: 'Редактировать',
    align: 'center'
  },
  {
    id: 'delete',
    label: 'Удалить',
    align: 'center'
  }
]

export const columnsSubscriptions = [
  {
    id: 'name',
    label: 'Абонемент',
    align: 'center'
  },
  {
    id: 'validity',
    label: 'Срок действия (мес)',
    align: 'center'
  },
  {
    id: 'count',
    label: 'Количество приемов',
    align: 'center'
  },
  {
    id: 'price',
    label: 'Стоимость',
    align: 'center'
  },
  {
    id: 'edit',
    label: '',
    align: 'center'
  },
  {
    id: 'switch',
    label: '',
    align: 'center'
  }
]

export const columnsCash = [
  {
    id: 'date',
    label: 'Дата',
    align: 'center'
  },
  {
    id: 'specialist',
    label: 'ФИО',
    align: 'center'
  },
  {
    id: 'speciality',
    label: 'Специальность',
    align: 'center'
  },
  {
    id: 'sum',
    label: 'Сумма',
    align: 'center'
  },
  {
    id: 'status',
    label: 'Статус',
    align: 'center'
  },
  {
    id: 'edit',
    label: '',
    align: 'center'
  }
]

export const columnsReciptions = [
  {
    id: 'data',
    label: 'Дата',
    align: 'center'
  },
  {
    id: 'time',
    label: 'Время',
    align: 'center'
  },
  {
    id: 'specialist',
    label: 'Специалист',
    align: 'center'
  },
  {
    id: 'phone',
    label: '',
    align: 'center'
  },
  {
    id: 'patient',
    label: 'Пациент',
    align: 'center'
  },
  {
    id: 'connected',
    label: 'Пац. подключился',
    align: 'center'
  },
  {
    id: 'repeated',
    label: 'Повторный обзвон',
    align: 'center'
  },
  {
    id: 'isPayed',
    label: 'Абонемент',
    align: 'center'
  },
  {
    id: 'refferal',
    label: 'Направление',
    align: 'center'
  },
  {
    id: 'complain',
    label: 'Жалоба',
    align: 'center'
  },
  {
    id: 'medcard',
    label: 'Медкарта',
    align: 'center'
  },

  {
    id: 'moderatorName',
    label: 'Имя админа',
    align: 'center'
  },
  {
    id: 'mark',
    label: 'Оценка',
    align: 'center'
  },
  {
    id: 'composition',
    label: 'Запись приема',
    align: 'center'
  },
  {
    id: 'menu',
    label: '',
    align: 'left'
  }
]

export const columnsSecondOpinionReciptions = [
  {
    id: 'data',
    label: 'Дата',
    align: 'center'
  },
  {
    id: 'time',
    label: 'Время',
    align: 'center'
  },
  {
    id: 'specialist',
    label: 'Специалист',
    align: 'center'
  },
  {
    id: 'patient',
    label: 'Пациент',
    align: 'center'
  },
  {
    id: 'medcard',
    label: 'Медкарта',
    align: 'center'
  },
  {
    id: 'delete',
    label: 'Удалить',
    align: 'center'
  },
  {
    id: 'menu',
    label: '',
    align: 'left'
  }
]
