import React, { useEffect, useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import {
  FormControl,
  InputLabel,
  Select as Selector,
  MenuItem
} from '@material-ui/core'
import { palette } from 'styles/colors'

const Select = ({
  classes,
  title,
  formik,
  selectValues,
  fieldName,
  otherStyles,
  selectorType,
  multiple,
  defaultValue,
  smallWidth,
  onChangeValue,
  ...props
}) => {
  const handleChange = (event) => {
    console.log('event.target.value', event.target.value)

    if (selectorType === 'specialistFio') {
      formik.setFieldValue(fieldName, event.target.value.fullName)
      formik.setFieldValue('specialist', event.target.value)
    }

    if (selectorType === 'specialist') {
      formik.setFieldValue(fieldName, event.target.value.fullName)
      formik.setFieldValue(
        'speciality',
        event.target.value.speciality?.jobTitle
      )

      formik.setFieldValue('specialist', event.target.value)
    } else {
      console.log('formik', formik.values)

      formik.setFieldValue(fieldName, event.target.value)
    }
    onChangeValue && onChangeValue(event.target.value)
  }

  return (
    <FormControl
      variant="outlined"
      className={smallWidth ? classes.wrapperSmall : classes.wrapper}
      style={{ ...otherStyles }}
      {...props}
    >
      <InputLabel id="select-label">{title}</InputLabel>
      <Selector
        label={title}
        id={fieldName}
        value={
          selectorType === 'clinics'
            ? formik.values[fieldName]
            : selectorType === 'specialityList' ||
              selectorType === 'specialistReception'
            ? formik.values[fieldName]
            : selectorType === 'specialist' || selectorType === 'specialistFio'
            ? formik.values[fieldName].fullName
            : fieldName !== 'speciality' ||
              !formik.values[fieldName] ||
              !formik.values[fieldName].id
            ? formik.values[fieldName]
            : formik.values[fieldName].id
        }
        onChange={handleChange}
        defaultValue={defaultValue ? defaultValue : 0}
        className={classes.selector}
        multiple={multiple}
      >
        {selectValues ? (
          selectValues.map((value, index) => (
            <MenuItem
              value={
                selectorType === 'region'
                  ? value.id
                  : selectorType === 'clinics'
                  ? value.id
                  : selectorType === 'specialityList' ||
                    selectorType === 'specialistReception'
                  ? value.id
                  : selectorType === 'specialist' ||
                    selectorType === 'specialistFio'
                  ? value
                  : selectorType === 'speciality'
                  ? value.id
                  : value.value
              }
              key={index}
            >
              {selectorType === 'clinics'
                ? `${value.title} ${value.address}`
                : selectorType === 'specialityList'
                ? value.jobTitle
                : selectorType === 'specialist' ||
                  selectorType === 'specialistFio' ||
                  selectorType === 'specialistReception'
                ? value.fullName
                : selectorType === 'speciality'
                ? value.title
                : value.name}
            </MenuItem>
          ))
        ) : (
          <></>
        )}
      </Selector>
    </FormControl>
  )
}

const styles = () => ({
  wrapper: {
    width: '300px',
    marginRight: '24px',
    display: 'flex',
    flexDirection: 'column',
    borderColor: palette.blue
  },
  wrapperSmall: {
    width: '200px',
    marginRight: '24px',
    display: 'flex',
    flexDirection: 'column',
    borderColor: palette.blue
  },
  selector: {
    height: '47px',
    top: '5px'
  },
  input: {
    borderWidth: '1px',
    borderColor: `${palette.blue} !important`
  },
  error: {
    borderWidth: '1px',
    borderColor: `red !important`
  },
  '@global': {
    '.MuiOutlinedInput-notchedOutline': {
      borderColor: palette.blue
    },
    '.MuiFormLabel-root': {
      color: palette.black
    }
  }
})

export default withStyles(styles)(Select)
