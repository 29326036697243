import React, { useState, useRef, useEffect } from 'react'
import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import InputMask from 'react-input-mask'
import Input from 'components/Input'
import { withStyles } from '@material-ui/core/styles'
import Button from 'components/Button'
import * as Yup from 'yup'
import 'yup-phone'
import { palette } from 'styles/colors'
import ModalWrapper from 'components/Modals/ModalWrapper'
import Portfolio from 'components/Modals/Portfolio'
import { Typography } from '@material-ui/core'
import Schedule from 'components/Modals/Schedule'
import FormCard from 'components/FormCard'
import defaultLogo from 'assets/defaultLogo.png'
import { CardMedia, CardActions } from '@material-ui/core'
import { imageFromServer } from 'api/http/imageFromServer'
import { getSpecialist } from 'redux/selectors/specialists'
import {
  editSpecialist as editSpecialistAction,
  getSpecialist as getSpecialistAction
} from 'redux/modules/specialists/actions'
import { getUser } from 'redux/selectors/auth'
import { Calendar } from 'arui-feather/calendar'
import ScheduleFullYear from './ScheduleFullYear'

const ButtonSpecialist = ({ text, submit, formik, openModal, ...props }) => {
  return (
    <Button
      text={text}
      otherStyles={{
        width: '280px',
        height: '45px',
        backgroundColor: palette.blueButton,
        color: palette.white,
        margin: '2em 1em'
      }}
      type={submit ? 'submit' : 'button'}
      onClick={() => (openModal ? openModal() : '')}
      {...props}
    />
  )
}

const specSchema = Yup.object().shape({
  fullName: Yup.string()
    .min(5, 'ФИО слишком короткое')
    .max(50, 'ФИО слишком длинное')
    .required('Это обязательное поле'),
  phone: Yup.string()
    .min(12, 'Неверно введен номер')
    .max(16, 'Неверно введен номер')
    .required('Это обязательное поле'),
  passport: Yup.string()
    .min(9, 'Неверно введен номер паспорта')
    .max(9, 'Неверно введен номер паспорта')
    .required('Это обязательное поле'),
  inn: Yup.string()
    .min(14, 'Неверно введен ИНН')
    .max(14, 'Неверно введен ИНН')
    .required('Это обязательное поле'),
  sex: Yup.string().required('Это обязательное поле'),
  startDate: Yup.string()
    .min(10, 'Неверно введена дата')
    .max(10, 'Неверно введена дата')
})

const Specialist = ({
  classes,
  editedObj,
  setEditedObj,
  handleSubmit,
  deleteTableValue,
  editTableValue,
  isLoading,
  error,
  setError,
  createTableValue
}) => {
  const dispatch = useDispatch()

  const hiddenFileInput = useRef(null)
  const [image, setImage] = useState('')

  const formik = useFormik({
    initialValues: {
      fullName: editedObj ? editedObj.fullName : '',
      phone: editedObj ? editedObj.phone : '',
      passport: editedObj ? editedObj.passport : '',
      inn: editedObj ? editedObj.inn : '',
      sex: editedObj ? editedObj.sex : '',
      photo: editedObj ? editedObj.photo : '',
      portfolio: editedObj
        ? editedObj.portfolio
        : { education: [], workExperience: [], courses: [] },
      experience: editedObj ? editedObj.experience : '',
      degree: editedObj ? editedObj.degree : '',
      category: editedObj ? editedObj.category : '',
      specialities:
        editedObj && editedObj.specialities
          ? editedObj.specialities.map((speciality) => speciality.id)
          : [],
      courseName: editedObj ? editedObj.courseName : '',
      year: editedObj ? editedObj.year : '',
      isActive: editedObj ? editedObj.isActive : false,
      placeOfWork: editedObj ? editedObj.placeOfWork : '',
      position: editedObj ? editedObj.position : '',
      startDate: editedObj ? editedObj.startDate : '',
      endDate: editedObj ? editedObj.endDate : '',
      typeEducation: editedObj ? editedObj.typeEducation : '',
      institution: editedObj ? editedObj.institution : '',
      specialityEducation: editedObj ? editedObj.specialityEducation : '',
      yearGraduated: editedObj ? editedObj.yearGraduated : '',
      isDuty: editedObj ? editedObj.isDuty : false,
      isSpecial: editedObj ? editedObj.isSpecial : false,
      descriptionRu: editedObj ? editedObj.descriptionRu : '',
      descriptionKg: editedObj ? editedObj.descriptionKg : '',
      price: editedObj ? editedObj?.price : '',
      medicalInstitution:
        editedObj && editedObj.medicalInstitution
          ? editedObj.medicalInstitution.id
          : '',
      regionId: editedObj && editedObj?.region ? editedObj?.region.id : '',
      parentSpecialist:
        editedObj && editedObj.parentSpecialistId
          ? editedObj.parentSpecialistId
          : ''
    },
    validationSchema: specSchema,
    onSubmit: (...props) =>
      editedObj
        ? handleSubmit(isOpenedPortfolio, setIsOpenedPortfolio, ...props)
        : handleSubmit(...props)
  })

  useEffect(() => {
    formik.setValues({
      fullName: editedObj ? editedObj.fullName : '',
      phone: editedObj ? editedObj.phone : '',
      passport: editedObj ? editedObj.passport : '',
      inn: editedObj ? editedObj.inn : '',
      sex: editedObj ? editedObj.sex : '',
      photo: editedObj ? editedObj.photo : '',
      portfolio: editedObj
        ? editedObj.portfolio
        : { education: [], workExperience: [], courses: [] },
      experience: editedObj ? editedObj.experience : '',
      degree: editedObj ? editedObj.degree : '',
      category: editedObj ? editedObj.category : '',
      specialities:
        editedObj && editedObj && editedObj.specialities
          ? editedObj.specialities.map((speciality) => speciality.id)
          : [],
      courseName: editedObj ? editedObj.courseName : '',
      year: editedObj ? editedObj.year : '',
      isActive: editedObj ? editedObj.isActive : false,
      placeOfWork: editedObj ? editedObj.placeOfWork : '',
      position: editedObj ? editedObj.position : '',
      startDate: editedObj ? editedObj.startDate : '',
      endDate: editedObj ? editedObj.endDate : '',
      typeEducation: editedObj ? editedObj.typeEducation : '',
      institution: editedObj ? editedObj.institution : '',
      specialityEducation: editedObj ? editedObj.specialityEducation : '',
      yearGraduated: editedObj ? editedObj.yearGraduated : '',
      isDuty: editedObj ? editedObj.isDuty : false,
      isSpecial: editedObj ? editedObj.isSpecial : false,
      descriptionRu: editedObj ? editedObj.descriptionRu : '',
      descriptionKg: editedObj ? editedObj.descriptionKg : '',
      price: editedObj ? editedObj?.price : '',
      medicalInstitution:
        editedObj && editedObj.medicalInstitution
          ? editedObj.medicalInstitution.id
          : '',
      regionId: editedObj && editedObj?.region ? editedObj?.region.id : '',
      parentSpecialist:
        editedObj && editedObj.parentSpecialistId
          ? editedObj.parentSpecialistId
          : ''
    })
  }, [editedObj])

  useEffect(() => {
    document.title = editedObj ? `Врач ${editedObj.fullName}` : 'Создание врача'
    return () => (document.title = 'Специалисты')
  }, [])

  const [isOpenedPortfolio, setIsOpenedPortfolio] = useState(false)

  const openPortfolio = () => {
    setIsOpenedPortfolio(true)
  }

  const closePortfolio = () => {
    setIsOpenedPortfolio(false)
    setError(false)
  }

  const [isOpenedShedule, setIsOpenedShedule] = useState(false)

  const openShedule = () => {
    setIsOpenedShedule(true)
  }

  const closeShedule = () => {
    setIsOpenedShedule(false)
  }

  const uploadMedia = (event) => {
    hiddenFileInput.current.click()
  }

  const handleChange = (event) => {
    const imageUrl = URL.createObjectURL(event.target.files[0])
    setImage(imageUrl)
    formik.setFieldValue('photo', event.target.files[0])
  }

  const user = useSelector(getUser)

  const [isOpenedFullCalendar, setIsOpenedFullCalendar] = useState(false)
  const [selectedDateForFull, setSelectedDateForFull] = useState('')

  const openFullCalendar = () => {
    setIsOpenedFullCalendar(true)
  }

  const closeFullCalendar = () => {
    setIsOpenedFullCalendar(false)
  }

  const [month, setMonth] = useState(Date.now())

  const calendarChange = (value) => {
    setSelectedDateForFull(value)
  }

  const [isOpenedFullYear, setIsOpenedFullYear] = useState(false)

  const openFullYear = () => {
    closeFullCalendar()
    setIsOpenedFullYear(true)
  }

  const closeFullYear = () => {
    setIsOpenedFullYear(false)
  }

  return (
    <div className={classes.wrapper}>
      <form onSubmit={formik.handleSubmit}>
        <div className={classes.logo}>
          {typeof formik.values.photo !== 'object' && formik.values.photo ? (
            <CardMedia
              image={imageFromServer(
                formik.values.photo[0] === '/'
                  ? formik.values.photo
                  : '/' + formik.values.photo
              )}
              component="img"
              className={classes.logoImg}
            />
          ) : image ? (
            <CardMedia
              image={image}
              component="img"
              className={classes.logoImg}
            />
          ) : (
            <CardMedia
              image={defaultLogo}
              component="img"
              className={classes.logoImg}
            />
          )}
          <CardActions>
            <input
              type="file"
              accept="image/*"
              ref={hiddenFileInput}
              onChange={handleChange}
              style={{ display: 'none' }}
              id="icon-button-photo"
              src={defaultLogo}
              multiple
            />
            <label htmlFor="icon-button-photo">
              <Button
                text={'Добавить фото'}
                otherStyles={{
                  border: 'none',
                  backgroundColor: palette.white,
                  boxShadow: 'none',
                  color: palette.blue
                }}
                onClick={uploadMedia}
              />
            </label>
          </CardActions>
        </div>
        <FormCard formik={formik} isSpecialist={true} isCreate={!editedObj} />
        <div className={classes.buttons}>
          {editedObj ? (
            <>
              {!formik.values.isDuty ? (
                <ButtonSpecialist
                  text={'Портфолио'}
                  openModal={openPortfolio}
                  formik={formik}
                  disabled={
                    !formik.values.fullName ||
                    !formik.values.phone ||
                    !formik.values.passport ||
                    !formik.values.inn ||
                    !formik.values.sex
                  }
                />
              ) : (
                <></>
              )}
              <ButtonSpecialist
                text={'Расписание'}
                openModal={openShedule}
                formik={formik}
              />
              <ButtonSpecialist
                text={'Годовое расписание'}
                openModal={openFullCalendar}
                formik={formik}
              />
            </>
          ) : (
            <></>
          )}
          <ButtonSpecialist
            text={'Сохранить изменения'}
            submit
            formik={formik}
            disabled={
              !formik.values.fullName ||
              !formik.values.phone ||
              !formik.values.passport ||
              !formik.values.inn ||
              !formik.values.sex ||
              user.status === 'call'
              // !formik.values.price
            }
            loader={isLoading}
          />
        </div>
        <ModalWrapper
          title={'Портфолио'}
          isOpened={isOpenedPortfolio}
          onClose={() => closePortfolio()}
        >
          <Portfolio
            formik={formik}
            onClose={() => closePortfolio()}
            handleSubmit={formik.handleSubmit}
            deleteTableValue={deleteTableValue}
            editTableValue={editTableValue}
            createTableValue={createTableValue}
            isLoading={isLoading}
            error={error}
          />
        </ModalWrapper>
        <ModalWrapper
          title={`Расписание (${editedObj ? editedObj.fullName : ''})`}
          isOpened={isOpenedShedule}
          onClose={() => closeShedule()}
        >
          <Schedule editedObj={editedObj} setEditedObj={setEditedObj} />
        </ModalWrapper>
        <ModalWrapper
          title={'Выбор даты'}
          isOpened={isOpenedFullCalendar}
          onClose={closeFullCalendar}
        >
          <p>Выберите дату, с которой</p>
          <p style={{ marginBottom: '1em' }}>начнете выставлять расписание</p>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Calendar
              value={selectedDateForFull}
              onValueChange={(newDate) => calendarChange(newDate)}
              onMonthChange={(newMonth) => setMonth(newMonth)}
              theme="alfa-light"
              className={classes.calendar}
              month={month}
              showToday={true}
            />
            <Button
              text={'Сохранить изменения'}
              otherStyles={{
                width: '280px',
                height: '45px',
                backgroundColor: palette.blueButton,
                color: palette.white,
                margin: '2em 1em'
              }}
              type={'button'}
              onClick={openFullYear}
              disabled={!selectedDateForFull}
            />
          </div>
        </ModalWrapper>
        <ModalWrapper
          title={'Расписание на год'}
          isOpened={isOpenedFullYear}
          onClose={() => closeFullYear()}
        >
          <ScheduleFullYear
            selectedDate={selectedDateForFull}
            specialist={editedObj}
            closeModal={closeFullYear}
          />
        </ModalWrapper>
      </form>
    </div>
  )
}

const styles = () => ({
  wrapper: {
    width: '700px'
  },
  field: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between'
  },
  logo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  calendar: {
    borderRadius: '20px',
    width: '300px'
  },
  logoImg: {
    width: '100px',
    height: '100px',
    borderRadius: '100px'
  },
  title: {
    fontWeight: 'bold',
    margin: '0 20px 10px 0'
  },
  buttons: {
    display: 'flex',
    justifyContent: 'center'
  },
  '@global': {
    '.MuiDialog-paperWidthSm': {
      maxWidth: '800px'
    },
    '.MuiButton-contained.Mui-disabled': {
      backgroundColor: `${palette.grey} !important`
    },
    '.MuiFormHelperText-root': {
      margin: '-21px 0',
      marginTop: '3px'
    }
  },
  '@global': {
    '.MuiDialog-paperWidthSm': {
      maxWidth: '900px !important'
    },
    '.calendar__day_state_today': {
      border: `1px solid ${palette.blueButton} !important`
    },
    '.calendar__day_state_current': {
      background: `${palette.blueButton} !important`
    },
    '.calendar_theme_alfa-light .calendar__day_state_today': {
      boxShadow: 'none'
    },
    '.calendar_theme_alfa-light .calendar__name_month, .calendar_theme_alfa-light .calendar__name_year':
      {
        backgroundColor: palette.calendar
      },
    '.calendar__name_month, .calendar__name_year': {
      fontSize: '15px'
    },
    '.calendar__event': {
      visibility: 'hidden'
    },
    '.calendar__day_event': {
      background: `${palette.blueButton} !important`,
      color: palette.white
    }
  }
})

export default withStyles(styles)(Specialist)
