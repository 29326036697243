import React, { useState, useEffect } from 'react'
import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import Input from 'components/Input'
import { withStyles } from '@material-ui/core/styles'
import Button from 'components/Button'
import * as Yup from 'yup'
import 'yup-phone'
import { palette } from 'styles/colors'
import ModalWrapper from 'components/Modals/ModalWrapper'
import { columnsHistory } from 'data/columns'
import TableWrapper from 'components/Table'
import Delete from 'components/Modals/Delete'
import NoContent from 'components/NoContent'
import Medcard from 'components/Modals/Medcard'
import EditAppointmentList from 'components/Modals/EditAppointmentList'
import FormCard from 'components/FormCard'
import {
  getPatientReceprionsItem,
  getReceptionsPagination
} from 'redux/selectors/patients'
import { getPatientReceprions as getPatientReceprionsAction } from 'redux/modules/patients/actions'

const ButtonPatient = ({ text, submit, openModal, isLoading }) => {
  return (
    <Button
      text={text}
      otherStyles={{
        width: '280px',
        height: '45px',
        backgroundColor: palette.blueButton,
        color: palette.white,
        margin: '2em 1em'
      }}
      type={submit ? 'submit' : 'button'}
      onClick={() => (openModal ? openModal() : '')}
      loader={isLoading}
    />
  )
}

const specSchema = Yup.object().shape({
  fullName: Yup.string()
    .min(5, 'ФИО слишком короткое')
    .max(50, 'ФИО слишком длинное')
    .required('Это обязательное поле'),
  phone: Yup.string()
    .min(12, 'Слишком короткий телефон')
    .max(16, 'Слишком длинный телефон')
    .required('Это обязательное поле'),
  passport: Yup.string()
    .min(9, 'Слишком короткое занчение паспорта')
    .max(9, 'Слишком длинное значение паспорта')
    .required('Это обязательное поле')
    .nullable('Это обязательное поле'),
  inn: Yup.string()
    .min(14, 'Слишком короткий ИНН')
    .max(14, 'Слишком длинный ИНН')
    .required('Это обязательное поле')
    .nullable('Это обязательное поле'),
  sex: Yup.string().required('Это обязательное поле'),
  birthDate: Yup.string()
    .required('Это обязательное поле')
    .nullable('Это обязательное поле')
})

const editSpecSchema = Yup.object().shape({
  fullName: Yup.string()
    .min(5, 'ФИО слишком короткое')
    .max(50, 'ФИО слишком длинное')
    .required('Это обязательное поле'),
  phone: Yup.string()
    .min(12, 'Слишком короткий телефон')
    .max(16, 'Слишком длинный телефон')
    .required('Это обязательное поле'),
  passport: Yup.string()
    .min(9, 'Слишком короткое занчение паспорта')
    .max(9, 'Слишком длинное значение паспорта'),
  inn: Yup.string()
    .min(14, 'Слишком короткий ИНН')
    .max(14, 'Слишком длинный ИНН')
    .required('Это обязательное поле')
    .nullable('Это обязательное поле'),
  sex: Yup.string().required('Это обязательное поле'),
  status: Yup.string().required('Это обязательное поле'),
  birthDate: Yup.string().nullable()
})

const Patient = ({ classes, editedObj, handleSubmit, isLoading }) => {
  const dispatch = useDispatch()

  const patientReceptions = useSelector(getPatientReceprionsItem)
  const receptionsPagination = useSelector(getReceptionsPagination)

  const formik = useFormik({
    initialValues: {
      fullName: editedObj ? editedObj.fullName : '',
      phone: editedObj ? editedObj.phone : '',
      passport: editedObj && editedObj.passport ? editedObj.passport : '',
      password: editedObj ? editedObj.password : '',
      inn: editedObj ? editedObj.inn : '',
      sex: editedObj ? editedObj.sex : '',
      birthDate: editedObj && editedObj.birthDate ? editedObj.birthDate : '',
      source: editedObj ? editedObj.source : '',
      status: editedObj ? editedObj.status : ''
    },
    validationSchema: editedObj ? editSpecSchema : specSchema,
    onSubmit: (...props) => handleSubmit(...props)
  })

  const fetchPatientReceprions = (page = receptionsPagination.page) => {
    dispatch(
      getPatientReceprionsAction(
        { id: editedObj.id },
        () => {},
        () => {},
        {
          page,
          count: receptionsPagination.perPage
        }
      )
    )
  }

  useEffect(() => {
    if (editedObj) {
      fetchPatientReceprions(receptionsPagination.page)
    }
  }, [])

  useEffect(() => {
    document.title = editedObj
      ? `Пациент ${editedObj.fullName}`
      : 'Создание пациента'
    return () => (document.title = 'Пациенты')
  }, [])

  const [isOpenedHistory, setIsOpenedHistory] = useState(false)

  const openHistory = () => {
    setIsOpenedHistory(true)
  }

  const closePortfolio = () => {
    setIsOpenedHistory(false)
  }

  const [isOpenedDelete, setIsOpenedDelete] = useState(false)
  const [deletedAdmission, setDeletedAdmission] = useState(null)

  const openDelete = (admission) => {
    setDeletedAdmission(admission)
    setIsOpenedDelete(true)
  }

  const closeDelete = () => {
    setDeletedAdmission(null)
    setIsOpenedDelete(false)
  }

  const [medcard, setMedcard] = useState('')
  const [isOpenedDetails, setIsOpenedEdit] = useState(false)

  const openDetails = (details) => {
    setMedcard(details)
    setIsOpenedEdit(true)
  }
  const closeDetails = (editedPatient) => {
    // setSpecialist(editedRecording)
    setIsOpenedEdit(false)
  }

  const [isOpenedAppointment, setIsOpenedAppointment] = useState(false)

  const openAppointment = () => {
    setIsOpenedAppointment(true)
  }
  const closeAppointment = () => {
    setIsOpenedAppointment(false)
  }

  const deleteAdmission = () => {}

  return (
    <form onSubmit={formik.handleSubmit} className={classes.wrapper}>
      <FormCard formik={formik} isPatient={true} editedObj={editedObj} />
      <div className={classes.buttons}>
        {editedObj ? (
          <>
            <ButtonPatient text={'История приемов'} openModal={openHistory} />
            <ButtonPatient
              text={'Записать на прием'}
              openModal={openAppointment}
            />
          </>
        ) : (
          <></>
        )}
        <ButtonPatient
          text={'Сохранить изменения'}
          submit
          formik={formik}
          isLoading={isLoading}
        />
      </div>
      <ModalWrapper
        title={'История'}
        isOpened={isOpenedHistory}
        onClose={() => closePortfolio()}
      >
        {patientReceptions.length > 0 ? (
          <div className={classes.table}>
            <TableWrapper
              page={receptionsPagination.page}
              count={receptionsPagination.perPage}
              amount={receptionsPagination.amount}
              items={patientReceptions}
              onChangePage={fetchPatientReceprions}
              isLoading={false}
              columns={columnsHistory}
              tableType={'history'}
              actions={{
                openDetails: openDetails,
                openDelete: openDelete
              }}
            />
          </div>
        ) : (
          <NoContent text={'Нет историй приемов'} />
        )}
      </ModalWrapper>
      <ModalWrapper
        title={'Медкарта приема'}
        isOpened={isOpenedDetails}
        onClose={() => closeDetails()}
      >
        <Medcard medcard={medcard} />
      </ModalWrapper>
      <ModalWrapper
        title={'Запись'}
        isOpened={isOpenedAppointment}
        onClose={() => closeAppointment()}
        style={{ width: '800px' }}
      >
        <EditAppointmentList
          user={editedObj}
          isPatient
          close={closeAppointment}
        />
      </ModalWrapper>
      <ModalWrapper
        title={'Вы уверены?'}
        isOpened={isOpenedDelete}
        onClose={() => closeDelete()}
      >
        <Delete
          onDelete={() => deleteAdmission()}
          onClose={() => closeDelete()}
        />
      </ModalWrapper>
    </form>
  )
}

const styles = () => ({
  wrapper: {
    width: '700px'
  },
  field: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between'
  },
  title: {
    fontWeight: 'bold',
    margin: '0 20px 10px 0'
  },
  buttons: {
    display: 'flex',
    justifyContent: 'center'
  },
  table: {
    width: '700px'
  },
  '@global': {
    '.MuiDialog-paperWidthSm': {
      maxWidth: '800px'
    },
    '.MuiButton-contained.Mui-disabled': {
      backgroundColor: `${palette.grey} !important`
    },
    '.MuiFormHelperText-root': {
      margin: '-21px 0',
      marginTop: '3px'
    },
    '.react-datepicker__triangle': {
      left: '-20px !important'
    }
  }
})

export default withStyles(styles)(Patient)
