import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { palette } from 'styles/colors'
import Button from 'components/Button'

const DeclineReception = ({ classes, onDelete, onClose, error }) => {
  return (
    <div>
      <div className={classes.root}>
        <Button
          otherStyles={{ margin: '0 1em 1em 0' }}
          fullWidth
          active
          text="Нет"
          onClick={() => onClose()}
        />
        <Button
          otherStyles={{ margin: '0 0 1em 1em' }}
          fullWidth
          text="Да"
          onClick={() => onDelete()}
        />
      </div>
      {error && (
        <p style={{ textAlign: 'center', color: 'red' }}>
          Не удалось отменить прием
        </p>
      )}
    </div>
  )
}

const styles = () => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    width: '300px'
  }
})

export default withStyles(styles)(DeclineReception)
