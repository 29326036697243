import React, { useCallback } from 'react'
import { TableRow, TableCell, IconButton } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { palette } from 'styles/colors'
import deleteIcon from 'assets/delete.png'
import Button from 'components/Button'
import { useHistory } from 'react-router-dom'

const formatDate = (date) => {
  let receptionDate =
    date.split('-')[2] + '.' + date.split('-')[1] + '.' + date.split('-')[0]
  return receptionDate
}

const Patients = ({ classes, patients, openDelete, openEdit }) => {
  const router = useHistory()

  const goToReceptions = useCallback((patient) => {
    router.push({ pathname: `/receptions`, state: { patientId: patient.id } })
  }, [])

  return patients.map((patient) => (
    <TableRow key={patient.id}>
      <TableCell
        style={{ width: '100px', borderColor: palette.black }}
        align="center"
      >
        {patient.fullName}
      </TableCell>
      <TableCell
        style={{ width: '100px', borderColor: palette.black }}
        align="center"
      >
        {patient.phone}
      </TableCell>
      <TableCell
        style={{ width: '100px', borderColor: palette.black }}
        align="center"
      >
        {patient.isSigned ? (
          <span style={{ color: palette.status.done }}>Оплачен</span>
        ) : (
          <span style={{ color: palette.status.failed }}>Не оплачен</span>
        )}
      </TableCell>
      <TableCell
        style={{ width: '100px', borderColor: palette.black }}
        align="center"
      >
        {patient.isSigned && patient.signStartedAt
          ? formatDate(patient.signStartedAt.split('T')[0])
          : '-'}
      </TableCell>
      <TableCell
        style={{ width: '100px', borderColor: palette.black }}
        align="center"
      >
        {patient.isSigned ? patient.limitAmount : '-'}
      </TableCell>
      <TableCell
        style={{ width: '100px', borderColor: palette.black }}
        align="center"
      >
        {patient.status === 'not_reliable'
          ? 'Ненадежный'
          : patient.status === 'new'
          ? 'Новый'
          : patient.status === 'first'
          ? 'Первый прием'
          : 'Надежный'}
      </TableCell>
      <TableCell
        style={{ width: '130px', borderColor: palette.black }}
        align="center"
      >
        <Button
          text={'Перейти к приемам'}
          onClick={() => goToReceptions(patient)}
          otherStyles={{
            width: '200px',
            backgroundColor: palette.blueButton,
            color: palette.white,
            marginBottom: '1em',
            marginTop: '1em'
          }}
        />
      </TableCell>
      <TableCell
        style={{ width: '100px', borderColor: palette.black }}
        align="center"
      >
        <Button
          text={'Подробнее'}
          otherStyles={{ background: 'none', boxShadow: 'none', margin: '0' }}
          onClick={() => openEdit({ ...patient })}
        />
      </TableCell>
      <TableCell
        style={{ width: '20px', borderColor: palette.black }}
        align="center"
      >
        <IconButton
          onClick={() => {
            openDelete(patient)
          }}
        >
          <img src={deleteIcon} alt="delete" width="15" />
        </IconButton>
      </TableCell>
    </TableRow>
  ))
}
const styles = () => ({})

export default withStyles(styles)(Patients)
